import React, { useEffect } from 'react';
import moment from 'moment';
import { getIfScopeInternational } from '../../../sources/list/scope';

const replaceTemplateCode = (templateCotnent, replaceData) => {
	const regex = /(\{departureCityCode\})|(\{departureCity\})|(\{arrivalCityCode\})|(\{arrivalCity\})/g;
	return templateCotnent.replace(regex, ($0) => {
		let replaceKey = $0.substr(1, $0.length - 2);
		if (replaceData[replaceKey]) {
			return replaceData[replaceKey];
		} else {
			return $0;
		}
	});
};

const SeoLinks = ({ ...props }) => {
	let { footer, prevCond, onGetPageFooter, globalConfigs } = props;

	let departureCity = prevCond.getIn(['flightSegments', 0, 'departureCityName']),
		departureCityCode = prevCond.getIn(['flightSegments', 0, 'departureCityCode']),
		arrivalCity = prevCond.getIn(['flightSegments', 0, 'arrivalCityName']),
		arrivalCityCode = prevCond.getIn(['flightSegments', 0, 'arrivalCityCode']),
		departureDate = prevCond.getIn(['flightSegments', 0, 'departureDate']),
		flightWay = prevCond.get('flightWay'),
		hotFlightRouteList = footer.get('hotFlightRouteList'),
		departureFlightRouteList = footer.get('departureFlightRouteList'),
		arrivalFlightRouteList = footer.get('arrivalFlightRouteList'),
		getToolUrl = () => {
			return `//m.ctrip.com/html5/flight/${departureCityCode}-${arrivalCityCode}-day-1.html`;
		},
		getUrl = (data) => {
			let dCode = data.get('departureCityCode'),
				aCode = data.get('arrivalCityCode'),
				redirectCityAndDateUrl = '';
			if (flightWay == 'S') {
				redirectCityAndDateUrl = `oneway-${dCode.toLowerCase()}-${aCode.toLowerCase()}?depdate=${departureDate}`;
			} else if (flightWay == 'D') {
				let arrivalDate = prevCond.getIn(['flightSegments', 1, 'departureDate']);
				redirectCityAndDateUrl = `round-${dCode.toLowerCase()}-${aCode.toLowerCase()}?depdate=${departureDate}_${arrivalDate}`;
			} else {
				let dData = moment().add(7, 'days').format('YYYY-MM-DD');
				redirectCityAndDateUrl = `oneway-${dCode.toLowerCase()}-${aCode.toLowerCase()}?depdate=${dData}`;
			}
			return `/international/search/${redirectCityAndDateUrl}&cabin=y_s&adult=1&child=0&infant=0`;
		},
		getCity = (data, index) => (
			<a href={getUrl(data)} key={index}>
				{data.get('departureCityName')}到{data.get('arrivalCityName')}
			</a>
		);

	useEffect(() => {
		getIfScopeInternational() &&
			onGetPageFooter({
				departureCityCode,
				arrivalCityCode,
			});
	}, [departureCityCode, arrivalCityCode, onGetPageFooter]);

	return (
		<div className="seo-links">
			{getIfScopeInternational() ? (
				<React.Fragment>
					<p>
						<span className="caption">机票工具箱</span>
						<span className="links">
							<a href={getToolUrl()}>
								{departureCity}到{arrivalCity}机票手机版
							</a>
						</span>
					</p>
					{hotFlightRouteList && hotFlightRouteList.size > 0 ? (
						<p>
							<span className="caption">国际/中国港澳台热门航线</span>
							<span className="links">{hotFlightRouteList.map((hot, index) => getCity(hot, index))}</span>
						</p>
					) : null}
					{departureFlightRouteList && departureFlightRouteList.size > 0 ? (
						<p>
							<span className="caption">{departureCity}飞机票出发</span>
							<span className="links">
								{departureFlightRouteList.map((departureFlightRoute, index) =>
									getCity(departureFlightRoute, index)
								)}
							</span>
						</p>
					) : null}
					{arrivalFlightRouteList && arrivalFlightRouteList.size > 0 ? (
						<p>
							<span className="caption">{arrivalCity}飞机票到达</span>
							<span className="links">
								{arrivalFlightRouteList.map((arrivalFlightRoute, index) =>
									getCity(arrivalFlightRoute, index)
								)}
							</span>
						</p>
					) : null}
				</React.Fragment>
			) : (
				<React.Fragment>
					<p>
						携程网提供
						<span className="links">
							<a href="//flights.ctrip.com/">特价机票</a>
						</span>
						,
						<span className="links">
							<a href="//flights.ctrip.com/schedule/">航班时刻表</a>
						</span>
						,
						<span className="links">
							<a href="//flights.ctrip.com/actualtime/">航班动态查询</a>
						</span>
						:
					</p>
					<p className="p_text">
						携程机票为您提供
						<span className="links">
							<a
								href={`//flights.ctrip.com/booking/lowprice-${departureCityCode}-${arrivalCityCode}----adu-1/`}
							>
								{departureCity}到{arrivalCity}特价机票
							</a>
						</span>
						,以及
						<span className="links">
							<a href="//flights.ctrip.com/booking/china-city-flights-sitemap.html">飞机票价格查询</a>
						</span>
						,
						<span className="links">
							<a href="//flights.ctrip.com/hot-flights.html">热门机票</a>
						</span>
						,
						<span className="links">
							<a href="//flights.ctrip.com/hot-airport.html">机场查询</a>.现在
							<a
								rel="nofollow noopener noreferrer"
								href="//passport.ctrip.com/user/reg/home"
								target="_blank"
							>
								注册
							</a>
						</span>
						携程会员即可获得1200积分和1300元消费券!
					</p>
					{globalConfigs.domesticSeoData.datas.map((data, index) => {
						let { title, items } = data;
						return (
							<p key={index}>
								<span className="caption">{title}</span>
								{items.map((item, index) => {
									let { text, link } = item,
										replaceData = {
											departureCityCode,
											departureCity,
											arrivalCity,
											arrivalCityCode,
										};
									let realText = replaceTemplateCode(text, replaceData),
										realLink = replaceTemplateCode(link, replaceData);
									return (
										<span key={index} className="links">
											<a href={realLink}>{realText}</a>
										</span>
									);
								})}
							</p>
						);
					})}
				</React.Fragment>
			)}
		</div>
	);
};

export default SeoLinks;
