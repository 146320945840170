import React from 'react';
import { connect } from 'react-redux';
import Disclaimer from '../../../components/list/footer/disclaimer';
import SeoLinks from '../../../components/list/footer/seoLinks';
import SmallSixProtection from '../../../components/sixProtection/SmallSixProtection';

import { prevCondSelector } from '../result/flight/baseSelector';
import { fetchPageFooter } from '../../../actions/list/footer';
import Breadcrumb from '../../../components/list/footer/breadcrumb';

const FooterContainer = (props) => {
	const { footer, prevCond, onGetPageFooter, globalConfigs, globalSwitches } = props;
	return (
		<div className="footer-wrapper">
			<Breadcrumb prevCond={prevCond} />
			{globalSwitches.showNewSixProtection ? <SmallSixProtection globalConfigs={globalConfigs} /> : null}
			<Disclaimer />
			<SeoLinks
				prevCond={prevCond}
				footer={footer}
				onGetPageFooter={onGetPageFooter}
				globalConfigs={globalConfigs}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	footer: state.getIn(['list', 'footer']),
	prevCond: prevCondSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
	onGetPageFooter(params) {
		dispatch(fetchPageFooter(params));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
