import * as types from '../../constants/list/actionTypes/footer.js'
import CONFIG_URL from '../../constants/list/api'
import { fetchGet } from '../common'

export const genPageFooter = data => ({ type: types.PAGE_FOOTER_DATA, data })


// 提交需求单
export const fetchPageFooter = (params) => dispatch => {
  return fetchGet(`${CONFIG_URL.getSearchPageFooter}?departureCityCode=${params.departureCityCode}&arrivalCityCode=${params.arrivalCityCode}`)
    .then(res => res && res.data || {})
    .then(json => {
      if (json.data) {
        dispatch(genPageFooter(json.data))
      }
    })
}
