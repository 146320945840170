import React from 'react';

// 增加面包屑导航，seo用
const Breadcrumb = ({ prevCond }) => {
  let departureCity = prevCond.getIn([
      'flightSegments',
      0,
      'departureCityName',
    ]),
    arrivalCity = prevCond.getIn(['flightSegments', 0, 'arrivalCityName']),
    flightWay = prevCond.get('flightWay');
  return flightWay == 'M' ? null : (
    <div className="breadcrumb">
      <h3>
        <a href="https://www.ctrip.com/" title="携程">
          携程
        </a>
      </h3>
      <span>&gt;</span>
      <h3>
        <a href="https://flights.ctrip.com/" title="机票">
          机票
        </a>
      </h3>
      <span>&gt;</span>
      <h3>
        {departureCity}到{arrivalCity}
        {flightWay == 'D' ? '往返' : ''}机票
      </h3>
    </div>
  );
};

export default Breadcrumb;
